import { template as template_f775d3f73ff4416fb16b82e8f6c43d0c } from "@ember/template-compiler";
const FKLabel = template_f775d3f73ff4416fb16b82e8f6c43d0c(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
