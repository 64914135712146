import { template as template_e18cc0704a9b4a4189440e01828bd113 } from "@ember/template-compiler";
const WelcomeHeader = template_e18cc0704a9b4a4189440e01828bd113(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
