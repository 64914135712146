import { template as template_307cea1e986b4c5b973c87d72690df91 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const RepliesCell = template_307cea1e986b4c5b973c87d72690df91(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="posts"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="replies"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RepliesCell;
