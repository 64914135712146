import { template as template_7eb026d4dd27483faa0257d3ab9742dc } from "@ember/template-compiler";
import Component from "@glimmer/component";
import icon from "discourse/helpers/d-icon";
export default class FKErrors extends Component {
    concatErrors(errors) {
        return errors.join(", ");
    }
    static{
        template_7eb026d4dd27483faa0257d3ab9742dc(`
    <p class="form-kit__errors" id={{@id}} aria-live="assertive" ...attributes>
      <span>
        {{icon "triangle-exclamation"}}
        {{this.concatErrors @error.messages}}
      </span>
    </p>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
